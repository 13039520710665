<template>
  <div class="create">
    <step-container :name="'创建支付'" :remark="'请先输入支付名称后，再配置支付信息'"
                    :steps="steps"
                    :current="current"></step-container>

    <div class="create-container">
      <div class="create-container-item">
        <p class="create-container-item-title">{{steps[current].title}}</p>

        <div class="create-container-base" v-if="current ===0">
          <el-form label-width="80px">
            <el-form-item label="支付名称">
              <el-input v-model="pay.name" size="small" placeholder="请输入支付名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handlePayCreate">下一步</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="current ===1" style="text-align: center">
          <pay-container :uuid="pay.uuid"></pay-container>
          <el-button class="commit" type="primary" @click="$router.back()">完成</el-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

  import PayContainer from '../Component/pay_container'
  import {mapGetters} from "vuex";


  export default {
    name: 'equipment-pay-create',
    components: {
      PayContainer
    },
    computed: {
      ...mapGetters(['prove']),
    },
    mounted: function () {
      this.pay.proveName = this.prove.name
    },
    data: function () {
      return {
        data: [],
        steps: [
          {
            title: '基础信息',
          },
          {
            title: '支付配置',
          },

        ],
        current: 0,
        pay: {
          uuid: '',
          name: '',
          proveName: '',

        }
      }
    },
    methods: {
      handlePayCreate() {
        if (!this.pay.name) {
          this.$message.warning('请输入支付名称')
          return
        }
        this.$api('mkt.pay.create',{}, this.pay).then(data => {
          this.pay.uuid = data
          this.current += 1
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .create {
    height: 100%;
    width: 100%;
    overflow-y: scroll;

    &-container {
      margin: $middle-space;
      margin-bottom: 0;


      &-item {
        padding: $container-padding 0;
        width: 60%;
        min-width: 640px;
        margin: auto;

        &-title {
          @include font-large-s();
          margin-bottom: $middle-space;
          padding-bottom: $small-space;
          border-bottom: 1px solid $content-border-color;
          padding-left: $small-space;
        }

      }

      &-base {
        width: 420px;
        margin: auto;
        margin-top: 120px;
        min-height: 320px;
      }

      background-color: $color-white;
      //border-radius: @box-radius;
      &-basic {

      }
    }

    .commit {
      margin-top: $large-space;
    }
  }
</style>
